import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";

import { FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST, CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST, FETCH_BANK_ACCOUNTS_REQUEST, UPDATE_DAILY_TRANACTION_SUMMERY_REQUEST } from "constants/DailyFinancialSummary";

import {
  fetchDailyFinancialSummaryFailure,
  fetchDailyFinancialSummarySuccess, createDailyTransactionSummeryReportFailure, createDailyTransactionSummeryReportSuccess,
  fetchBankAccountSuccess,
  fetchBankAccountFailure,
  updateDailyTransactionSummerySuccess,
  updateDailyTransactionSummeryFailure
} from "redux/actions/DailyFinancialSummary";

import DailyFinancialSummary from "services/DailyFinancialSummary";

export function* onFetchDailyFinancialSummary() {
  yield takeLatest(FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST, function* (payload) {
    try {

      const result = yield call(DailyFinancialSummary.get, payload.payload);
      if (result.status) {
        yield put(fetchDailyFinancialSummarySuccess(result.data));
      } else {
        yield put(fetchDailyFinancialSummaryFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDailyFinancialSummaryFailure(error.message));
    }
  });
}

export function* onCreateDailyTransactionSummeryReport() {
  yield takeLatest(CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST, function* (payload) {
    try {

      const result = yield call(DailyFinancialSummary.createDailyTransactionSummeryReport, payload.payload);
      if (result.status) {
        yield put(createDailyTransactionSummeryReportSuccess(result.data));
      } else {
        yield put(createDailyTransactionSummeryReportFailure(result.message));
      }
    } catch (error) {
      yield put(createDailyTransactionSummeryReportFailure(error.message));
    }
  });
}

export function* onFetchBankAccount() {
  yield takeLatest(FETCH_BANK_ACCOUNTS_REQUEST, function* () {
    try {

      const result = yield call(DailyFinancialSummary.getBankAccount);
      if (result) {
        yield put(fetchBankAccountSuccess(result));
      } else {
        yield put(fetchBankAccountFailure(result.message));
      }
    } catch (error) {
      yield put(fetchBankAccountFailure(error.message));
    }
  });
}

export function* onUpdateDailyTransactionSummery() {
  yield takeLatest(UPDATE_DAILY_TRANACTION_SUMMERY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(DailyFinancialSummary.updateDailyTransactionSummery, payload);
      if (result) {
        yield put(updateDailyTransactionSummerySuccess(result));
        message.success("Update successfully");
      } else {
        yield put(updateDailyTransactionSummeryFailure(result.message));
        message.error("Something went wrong");
      }
    } catch (error) {
      yield put(updateDailyTransactionSummeryFailure(error.message));

    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(onFetchDailyFinancialSummary),
    fork(onCreateDailyTransactionSummeryReport),
    fork(onFetchBankAccount),
    fork(onUpdateDailyTransactionSummery)
  ]);
}
