import {
  FETCH_EXISTING_PATIENT_REQUEST,
  FETCH_EXISTING_PATIENT_SUCCESS,
  FETCH_EXISTING_PATIENT_FAILURE,
} from "constants/PatientSearch";



//patients
export const fetchPatientSearchRequest = ( startDate,  endDate, searchKey,mrnnumber,phonenumber,sex,patientid,sampleid, searchtype="without_order_and_result", lalCode) => ({
  type: FETCH_EXISTING_PATIENT_REQUEST,
  payload:{
    startDate,  endDate, searchKey,mrnnumber, phonenumber,sex,patientid,sampleid, searchtype, lalCode
  }
});

export const fetchPatientSearchSuccess = (searchedPatient) => ({
  type: FETCH_EXISTING_PATIENT_SUCCESS,
  payload:  searchedPatient,
});

export const fetchPatientSearchFailure = (error) => ({
  type: FETCH_EXISTING_PATIENT_FAILURE,
  payload: error,
});


//end patients

// export const togglePatientsearchForm = () => ({
//   type: SHOW_SEARCH_FORM,
// });
