
import {
    FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
    FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE,
    FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS,
    CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST,
    CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE,
    CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS,
    FETCH_BANK_ACCOUNTS_REQUEST,
    FETCH_BANK_ACCOUNTS_SUCCESS,
    FETCH_BANK_ACCOUNTS_FAILURE,
    UPDATE_DAILY_TRANACTION_SUMMERY_REQUEST,
    UPDATE_DAILY_TRANACTION_SUMMERY_SUCCESS,
    UPDATE_DAILY_TRANACTION_SUMMERY_FAILURE
} from "constants/DailyFinancialSummary";

const initState = {
    loading: false,
    list: [],
    summeryreports: [],
    errorMessage: {},
    bankAccounts: [],
    dailyTransaction: []
}

const DailyFinancialSummary = (state = initState, action) => {
    switch (action.type) {
        case FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST:
            return {
                ...state,
                loading: true,

                //added
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            }

        case FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS:
            return {
                ...state,
                list: action.payload,
                loading: false
            }

        case FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE:
            return {
                ...state,
                loading: false
            }
        case CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST:
            return {
                ...state,
                loading: true

            }
        case CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS:
            return {
                ...state,
                summeryreports: action.payload,
                loading: false,
                updating: false
            }
        case CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                updating: false
            }


        case FETCH_BANK_ACCOUNTS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case FETCH_BANK_ACCOUNTS_SUCCESS:
            return {
                ...state,
                loading: false,
                bankAccounts: action.payload
            }

        case FETCH_BANK_ACCOUNTS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            }

        case UPDATE_DAILY_TRANACTION_SUMMERY_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case UPDATE_DAILY_TRANACTION_SUMMERY_SUCCESS:
            return {
                ...state,
                loading: false,
                dailyTransaction: action.payload
            }

        case UPDATE_DAILY_TRANACTION_SUMMERY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            }
        default: return state;
    }
}

export default DailyFinancialSummary;