export const FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS = "FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS"
export const FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE = "FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE"
export const FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST = "FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST"

export const CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST = "CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST"
export const CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE = "CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE"
export const CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS = "CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS"

export const FETCH_BANK_ACCOUNTS_REQUEST = "FETCH_BANK_ACCOUNTS_REQUEST";
export const FETCH_BANK_ACCOUNTS_SUCCESS = "FETCH_BANK_ACCOUNTS_SUCCESS";
export const FETCH_BANK_ACCOUNTS_FAILURE = "FETCH_BANK_ACCOUNTS_FAILURE";

export const UPDATE_DAILY_TRANACTION_SUMMERY_REQUEST = "UPDATE_DAILY_TRANACTION_SUMMERY_REQUEST";
export const UPDATE_DAILY_TRANACTION_SUMMERY_SUCCESS = "UPDATE_DAILY_TRANACTION_SUMMERY_SUCCESS";
export const UPDATE_DAILY_TRANACTION_SUMMERY_FAILURE = "UPDATE_DAILY_TRANACTION_SUMMERY_FAILURE";

