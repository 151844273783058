import {
    FETCH_AGENCY_TEST_FAILURE,
    FETCH_AGENCY_TEST_REQUEST,
    FETCH_AGENCY_TEST_SUCCESS,
    CREATE_AGENCY_TEST_FAILURE,
    CREATE_AGENCY_TEST_SUCCESS,
    CREATE_AGENCY_TEST_REQUEST,
    UPDATE_AGENCY_TEST_REQUEST,
    UPDATE_AGENCY_TEST_FAILURE,
    UPDATE_AGENCY_TEST_SUCCESS
} from "constants/AgencyTests"

const initState = {
    loading: false,
    list: [],
    errorMessage: "",
    updating: false
}

const AgencyTests = (state = initState, action) => {
    switch (action.type) {
        case FETCH_AGENCY_TEST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_AGENCY_TEST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload
            }
        case FETCH_AGENCY_TEST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            }
        case CREATE_AGENCY_TEST_REQUEST:
            return {
                ...state,
                loading: true,
                updating: true,
            }
        case CREATE_AGENCY_TEST_SUCCESS:
            return {
                ...state,
                loading: false,
                updating: false
            }
        case CREATE_AGENCY_TEST_FAILURE:
            return {
                ...state,
                loading: false,
                updating: false
            }
        case UPDATE_AGENCY_TEST_REQUEST:
            return {
                ...state,
                loading: true,
                updating: true
            }
        case UPDATE_AGENCY_TEST_SUCCESS:
            return {
                ...state,
                loading: false,
                updating: false
            }
        case UPDATE_AGENCY_TEST_FAILURE:
            return {
                ...state,
                loading: false,
                updating: false
            }
        default:
            return state
    }
}
export default AgencyTests;