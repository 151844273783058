import fetch from "auth/FetchInterceptor";

const DailyFinancialSummary = {};

DailyFinancialSummary.get = function (payload) {
  // console.log('--->pay',payload)
  return fetch({
    url: `/reports/daily-financial-summary?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${payload.agency}
    &cashiercode=${payload.cashiercode}&cashorcredit=${payload.cashorcredit}`,
    // mode: 'no-cors'
  });
};

DailyFinancialSummary.createDailyTransactionSummeryReport = function (payload) {
  const agency = "calculate-summary";
  return fetch({
    // url: `/reports/daily-financial-summary?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${payload.agency}&cashiercode=${payload.cashiercode}&cashorcredit=${payload.cashorcredit}`,
    url: `/reports/do-daily-financial-summary-report?startdate=${payload.startDate}&enddate=${payload.endDate}&agency=${encodeURIComponent(agency)}&cashiercode=${payload.cashiercode}&cashorcredit=${payload.cashorcredit}`,
  });
};

DailyFinancialSummary.getBankAccount = function () {
  return fetch({
    url: `/get-bank-accounts`,
  });
};

DailyFinancialSummary.updateDailyTransactionSummery = function (data) {
  return fetch({
    url: `/update-daily-transaction-summery`,
    method: 'put',
    data
  });
};



export default DailyFinancialSummary;