import { env } from "configs/EnvironmentConfig";

const checkMissedOrders = async (startDate, endDate) => {
    try {
      const response = await fetch(`${env.API_BASE_URL}/missed-orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({ startDate, endDate }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching missed results:', error);
    }
  };
  
  export default checkMissedOrders;