import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import { message } from "antd";
import { FETCH_AGENCY_TEST_REQUEST, UPDATE_AGENCY_TEST_REQUEST } from "constants/AgencyTests";
import {
  all,
  put,
  fork,
  call,
  takeLatest,
} from "redux-saga/effects";
import { fetchAgencyTestFailure, fetchAgencyTestSuccess, updateAgencyTestFailure, updateAgencyTestSuccess } from "redux/actions";

import AgencyTests from "services/AgencyTests"

export function* onFetchAgencyTest() {
  yield takeLatest(FETCH_AGENCY_TEST_REQUEST, function* (payload) {
    try {
      const result = yield call(AgencyTests.get, payload.payload); //where payload.payload returns me the agency name that is currently selected agencyname
      if (result.status) {
        yield put(fetchAgencyTestSuccess(result.data));
      } else {
        yield put(fetchAgencyTestFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(fetchAgencyTestFailure(error.message));
    }
  });
}

export function* onUpdateAgencyTest() {
  yield takeLatest(UPDATE_AGENCY_TEST_REQUEST, function* (payload) {
    try {
      const result = yield call(
        AgencyTests.update,
        payload.payload
      );
      if (result.status) {
        message.success("Successfully Updated");
        yield put(updateAgencyTestSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(updateAgencyTestFailure(result.data.errors));
      } else {
        message.error("Something went wrong");
        yield put(updateAgencyTestFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateAgencyTestFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchAgencyTest),
    fork(onUpdateAgencyTest),
  ]);
}