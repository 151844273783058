import {
    FETCH_SPECIMEN_TRACKING_REQUEST,
    FETCH_SPECIMEN_TRACKING_SUCCESS,
    FETCH_SPECIMEN_TRACKING_FAILURE,
    FETCH_LIST_OF_SPECIMEN_TESTS_REQUESET,
    FETCH_LIST_OF_SPECIMEN_TESTS_SUCCESS,
    FETCH_LIST_OF_SPECIMEN_TESTS_FAILURE,
    UPDATE_SPECIMEN_PHLEBOTOMY_REQUESET,
    UPDATE_SPECIMEN_PHLEBOTOMY_SUCCESS,
    UPDATE_SPECIMEN_PHLEBOTOMY_FAILURE,
    UPDATE_SPECIMENT_SRD_SUCCESS,
    UPDATE_SPECIMEN_SRD_REQEUST,
    UPDATE_SPECIMEN_SRD_FAILURE,
    FETCH_SAMPLE_COLLECTED_ORDERS_REPORT_FAILURE,
    FETCH_SAMPLE_COLLECTED_ORDERS_REPORT_SUCCESS,
    FETCH_SAMPLE_COLLECTED_ORDERS_REPORT_REQUEST,
} from "constants/specimen_tracking";

export const fetchSpecimenTrackingRequest = (startDate, endDate, agency, department) => ({
    type: FETCH_SPECIMEN_TRACKING_REQUEST,
    payload: {
        startDate, endDate, agency, department
    }
});

export const fetchSpecimenTrackingSuccess = (payload) => ({
    type: FETCH_SPECIMEN_TRACKING_SUCCESS,
    payload: payload
});

export const fetchSpecimenTrackingFailure = (payload) => ({
    type: FETCH_SPECIMEN_TRACKING_FAILURE,
    payload: payload
});


export const fetchListOfSpecimenTestsRequest = (sampleid) => ({
    type: FETCH_LIST_OF_SPECIMEN_TESTS_REQUESET,
    payload: {
        sampleid
    }
});

export const fetchListOfSpecimenTestsSuccess = (payload) => ({
    type: FETCH_LIST_OF_SPECIMEN_TESTS_SUCCESS,
    payload: payload
});

export const fetchListOfSpecimenTestsFailure = (payload) => ({
    type: FETCH_LIST_OF_SPECIMEN_TESTS_FAILURE,
    payload: payload
});

//PHELEBOTOMY UPDATE
export const updateSpecimenTestPhelebotomyRequest = (record) => ({
    type: UPDATE_SPECIMEN_PHLEBOTOMY_REQUESET,
    payload: { record }
});

// export const updateSpecimenTestPhelebotomyRequest = (record) => {
//     const action = {
//         type: UPDATE_SPECIMEN_PHLEBOTOMY_REQUESET,
//         payload: {
//             record
//         }
//     };
//     console.log('Payload:', action.payload);
//     return action;
// };

export const updateSpecimenTestPhelebotomySuccess = (test_id) => ({
    type: UPDATE_SPECIMEN_PHLEBOTOMY_SUCCESS,
    payload: test_id
});

export const updateSpecimenTestsPhelebotomyFailure = (payload) => ({
    type: UPDATE_SPECIMEN_PHLEBOTOMY_FAILURE,
    payload: payload
});


//SRD UPDATTE
export const updateSpecimenSrdRequest = (record) => ({
    type: UPDATE_SPECIMEN_SRD_REQEUST,
    payload: {
        record
    }
});

export const updateSpecimenSrdSuccess = (test_id) => ({
    type: UPDATE_SPECIMENT_SRD_SUCCESS,
    payload: test_id
});

export const updateSpecimenSrdFailure = (payload) => ({
    type: UPDATE_SPECIMEN_SRD_FAILURE,
    payload: payload
});

export const fetchSpecimenSrdReportRequest = (startDate, endDate, agency) => {
    return {
        type: FETCH_SAMPLE_COLLECTED_ORDERS_REPORT_REQUEST,
        payload: {
            startDate,
            endDate,
            agency
        }
    }
}

export const fetchSpecimenSrdReportSuccess = (payload) => {
    return {
        type: FETCH_SAMPLE_COLLECTED_ORDERS_REPORT_SUCCESS,
        payload: payload
    }
}

export const fetchSpecimenSrdReportFailure = (payload) => {
    return {
        type: FETCH_SAMPLE_COLLECTED_ORDERS_REPORT_FAILURE,
        payload: payload
    }
}
