import {
  FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
  FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE,
  FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS,

  CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST,
  CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE,
  CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS,
  FETCH_BANK_ACCOUNTS_REQUEST,
  FETCH_BANK_ACCOUNTS_SUCCESS,
  FETCH_BANK_ACCOUNTS_FAILURE,
  UPDATE_DAILY_TRANACTION_SUMMERY_REQUEST,
  UPDATE_DAILY_TRANACTION_SUMMERY_SUCCESS,
  UPDATE_DAILY_TRANACTION_SUMMERY_FAILURE

} from "constants/DailyFinancialSummary";


export const fetchDailyFinancialSummaryRequest = (startDate, endDate, agency, cashiercode, cashorcredit) => {
  const payload = {
    startDate,
    endDate,
    agency,
    cashiercode,
    cashorcredit,
  };
  return {
    type: FETCH_DAILY_FINANCIAL_SUMMARY_REQUEST,
    payload: payload
  };
};

export const fetchDailyFinancialSummarySuccess = (payload) => ({
  type: FETCH_DAILY_FINANCIAL_SUMMARY_SUCCESS,
  payload: payload
});

export const fetchDailyFinancialSummaryFailure = (payload) => ({
  type: FETCH_DAILY_FINANCIAL_SUMMARY_FAILURE,
  payload: payload
});

export const createDailyTransactionSummeryReportRequest = (startDate, endDate, agency, cashiercode, cashorcredit) => {
  const payload = {
    startDate,
    endDate,
    agency,
    cashiercode,
    cashorcredit,
  };
  return {
    type: CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_REQUEST,
    payload: payload
  };
}


export const createDailyTransactionSummeryReportSuccess = (payload) => ({
  type: CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_SUCCESS,
  payload: payload
});

export const createDailyTransactionSummeryReportFailure = (payload) => ({
  type: CREATE_DAILY_TRANSACTION_SUMMERY_REPORT_FAILURE,
  payload: payload
});

export const fetchBankAccountRequest = () => {
  return {
    type: FETCH_BANK_ACCOUNTS_REQUEST,
  }
}

export const fetchBankAccountSuccess = (payload) => {
  return {
    type: FETCH_BANK_ACCOUNTS_SUCCESS,
    payload: payload
  }
}

export const fetchBankAccountFailure = (payload) => {
  return {
    type: FETCH_BANK_ACCOUNTS_FAILURE,
    payload: payload
  }
}

export const updateDailyTransactionSummeryRequest = (payload) => {
  return {
    type: UPDATE_DAILY_TRANACTION_SUMMERY_REQUEST,
    payload: payload
  }
}

export const updateDailyTransactionSummerySuccess = (payload) => {
  return {
    type: UPDATE_DAILY_TRANACTION_SUMMERY_SUCCESS,
    payload: payload
  }
}

export const updateDailyTransactionSummeryFailure = (payload) => {
  return {
    type: UPDATE_DAILY_TRANACTION_SUMMERY_FAILURE,
    payload: payload
  }
}
